import React, {useContext, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import graphql from 'babel-plugin-relay/macro';
import {TimesheetsPage_timesheets$key} from '../../__generated__/TimesheetsPage_timesheets.graphql';
import {useMutation, useQuery, useRefetchable} from 'relay-hooks/lib';
import {TimesheetsPageQuery} from '../../__generated__/TimesheetsPageQuery.graphql';
import {makeStyles} from '@material-ui/core/styles';
import {Form, Formik} from 'formik';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import CreateIcon from '@material-ui/icons/Create';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import {useNavigate} from 'react-router';
import {TimesheetsPageDownloadMutation} from '../../__generated__/TimesheetsPageDownloadMutation.graphql';
import {TimesheetsPageDeleteMutation} from '../../__generated__/TimesheetsPageDeleteMutation.graphql';
import {Toast} from '../../modules/Toast';
import {useAuthContext} from '../../context/AuthContext';
import {EditTimesheet} from '../../modules/EditTimesheet/EditTimesheet';
import {ConfirmDialog} from '../../modules/ConfirmDialogProvider/ConfirmDialogProvider';
import {TimesheetsPageDownloadLastMonthMutation} from '../../__generated__/TimesheetsPageDownloadLastMonthMutation.graphql';
import {UserRole} from '../../../../../types';

const useStyles = makeStyles(() =>
  createStyles({
    columnHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px',
      width: '100%',
      flexDirection: 'row',
    },
    cell: {
      '&:hover': {
        backgroundColor: '#faf9f7',
        cursor: 'pointer',
      },
    },
  })
);

const query = graphql`
  query TimesheetsPageQuery($search: String, $first: Int, $after: String) {
    viewer {
      user {
        ...TimesheetsPage_timesheets
      }
    }
  }
`;
const fragment = graphql`
  fragment TimesheetsPage_timesheets on User
    @refetchable(queryName: "TimesheetsPageRefetchQuery") {
    id
    firstName
    lastName
    email
    mobileNumber
    timesheets(
      q: $search
      first: $first
      after: $after
      sort: {field: ID, direction: DESCENDING}
    ) {
      total
      edges {
        node {
          id
          totalActualHours
          userId
          user {
            id
            firstName
            lastName
          }
          lines {
            id
            actualHours
            description
            position
            userTimesheetId
          }
          timesheet {
            id
            billableHours
            description
            name
            startDate
            endDate
          }
        }
      }
    }
  }
`;

interface UserTimesheetLineInterface {
  id: string | null;
  userTimesheetId: string | null;
  position: number;
  description: string | null;
  actualHours: number | null;
}

interface TimesheetInterface {
  id: string;
  billableHours: number;
  description: string;
  name: string;
  startDate: string;
  endDate: string;
}
interface UserTimesheetInterface {
  id: string | null;
  totalActualHours: number | null;
  userId: string | null;
  lines: readonly UserTimesheetLineInterface[];
  timesheet: TimesheetInterface;
}

interface Props {
  node: TimesheetsPage_timesheets$key | null;
}

interface SearchProps {
  search: string;
}

export function TimesheetsPage({node}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {auth, hasRole} = useAuthContext();

  const dialogue = useContext(ConfirmDialog);

  const [editTimesheet, setEditTimesheet] = useState(false);
  const [
    selectedTimesheet,
    setSelectedTimesheet,
  ] = useState<UserTimesheetInterface | null>(null);

  const [downloadTimesheetMutation] = useMutation<
    TimesheetsPageDownloadMutation
  >(graphql`
    mutation TimesheetsPageDownloadMutation($input: DownloadTimesheetInput!) {
      downloadTimesheet(input: $input) {
        url
      }
    }
  `);

  const [deleteTimesheetMutation] = useMutation<
    TimesheetsPageDeleteMutation
  >(graphql`
    mutation TimesheetsPageDeleteMutation($input: DeleteTimesheetInput!) {
      deleteTimesheet(input: $input) {
        clientMutationId
      }
    }
  `);

  const [downloadLastMonthTimesheets, {loading}] = useMutation<
    TimesheetsPageDownloadLastMonthMutation
  >(
    graphql`
      mutation TimesheetsPageDownloadLastMonthMutation {
        downloadLastMonthTimesheets
      }
    `
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState();

  const [fragmentNode, refetch] = useRefetchable(fragment, node);

  const userId = fragmentNode?.id;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    refetch({
      id: userId || '',
      search: searchValue,
      first: rowsPerPage,
      after: searchValue
        ? btoa(`arrayconnection:-1`)
        : btoa(`arrayconnection:${rowsPerPage * page - 1}`),
    });
  }, [refetch, searchValue, rowsPerPage, page, userId]);

  return (
    <Box>
      <Box
        pl={6}
        pb={3}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant="h4">Timesheets</Typography>

        <Box>
          {auth.authenticated && hasRole(UserRole.ADMIN) ? (
            <Button
              style={{margin: '0px 15px'}}
              color={'secondary'}
              onClick={async () => {
                try {
                  const response = await downloadLastMonthTimesheets({
                    variables: {},
                  });

                  if (response) {
                    window.open(response.downloadLastMonthTimesheets);
                  }
                } catch (e) {
                  Toast('error', e.message);
                }
              }}
            >
              {loading ? (
                <CircularProgress size={26} style={{color: 'black'}} />
              ) : (
                'Download Last Month Timesheets'
              )}
            </Button>
          ) : null}

          <Button
            color={'primary'}
            onClick={() => {
              navigate('/capturetimesheet');
            }}
          >
            <CreateIcon /> Capture
          </Button>
        </Box>
      </Box>

      <Box>
        <Formik<SearchProps>
          initialValues={{
            search: '',
          }}
          onSubmit={({search}) => {
            setSearchValue(search);
            setPage(0);
          }}
        >
          {({submitForm}) => {
            return (
              <Box display="flex" justifyContent="center" pl={5} pb={5}>
                <Form
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      submitForm();
                    }
                  }}
                >
                  <TableContainer
                    style={{
                      borderRadius: '4px',
                      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'h6'}>Timesheets</Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <FTextField
                              field={{name: 'search'}}
                              label="Search"
                            />
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Month</Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Billing Period</Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Billable Hours</Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Actual Hours</Typography>
                            </Box>
                          </TableCell>

                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Actions</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {fragmentNode?.timesheets.edges.map((timesheet) => {
                        return (
                          <TableRow>
                            <TableCell>
                              {timesheet.node.timesheet.name}
                            </TableCell>
                            <TableCell>
                              {timesheet.node.timesheet.description} -{' '}
                              {timesheet.node.user.firstName}{' '}
                              {timesheet.node.user.lastName}
                            </TableCell>
                            <TableCell>
                              {timesheet.node.timesheet.billableHours}
                            </TableCell>
                            <TableCell>
                              {timesheet.node.totalActualHours}
                            </TableCell>
                            <TableCell>
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                              >
                                <Tooltip title="Download" aria-label="Download">
                                  <IconButton
                                    disabled={loading}
                                    aria-label={'Download'}
                                    onClick={async () => {
                                      try {
                                        const response = await downloadTimesheetMutation(
                                          {
                                            variables: {
                                              input: {
                                                userTimesheetId:
                                                  timesheet.node.id || '',
                                              },
                                            },
                                          }
                                        );

                                        if (response) {
                                          window.open(
                                            response?.downloadTimesheet?.url
                                          );
                                        }
                                      } catch (e) {
                                        Toast('error', e.message);
                                      }
                                    }}
                                  >
                                    <GetAppIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit" aria-label="Edit">
                                  <IconButton
                                    disabled={loading}
                                    aria-label={'Edit'}
                                    onClick={() => {
                                      setSelectedTimesheet(timesheet.node);
                                      setEditTimesheet(true);
                                    }}
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete" aria-label="Delete">
                                  <IconButton
                                    disabled={loading}
                                    aria-label={'Delete'}
                                    onClick={async () => {
                                      dialogue.notify({
                                        title: (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <ContactSupportIcon
                                              color="primary"
                                              fontSize="large"
                                            />
                                            <Typography
                                              style={{fontSize: '28px'}}
                                            >
                                              <b style={{color: 'black'}}>
                                                Confirmation
                                              </b>
                                            </Typography>
                                          </Box>
                                        ),
                                        body: (
                                          <Box>
                                            <Typography>
                                              {' '}
                                              Are you sure you want to delete
                                              this timesheet? This action is
                                              permanent and cannot be undone. To
                                              continue, type DELETE and click
                                              &quot;Confirm&quot;
                                            </Typography>
                                          </Box>
                                        ),
                                        secret: 'DELETE',
                                        handlePromptResponse: async () => {
                                          const response = await deleteTimesheetMutation(
                                            {
                                              variables: {
                                                input: {
                                                  userTimesheetId:
                                                    timesheet.node.id || '',
                                                },
                                              },
                                            }
                                          );

                                          if (response) {
                                            Toast(
                                              'success',
                                              'Successfully deleted timesheet'
                                            );
                                            setPage(0);
                                            refetch({
                                              id: userId || '',
                                              search: '',
                                              first: rowsPerPage,
                                              after: btoa(`arrayconnection:-1`),
                                            });
                                          } else {
                                            Toast(
                                              'error',
                                              'Something went wrong'
                                            );
                                          }
                                        },
                                      });
                                    }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      component="span"
                      count={
                        fragmentNode?.timesheets.total
                          ? fragmentNode?.timesheets.total
                          : 0
                      }
                      page={page}
                      onChangePage={(_event, pageNumber) => {
                        setPage(pageNumber);
                      }}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Form>
              </Box>
            );
          }}
        </Formik>
        {editTimesheet ? (
          <EditTimesheet
            open={editTimesheet}
            close={() => {
              setPage(0);
              refetch({
                id: userId || '',
                search: '',
                first: rowsPerPage,
                after: btoa(`arrayconnection:-1`),
              });
              setEditTimesheet(!editTimesheet);
            }}
            cancel={() => setEditTimesheet(!editTimesheet)}
            userTimesheet={selectedTimesheet}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export function TimesheetsPageWrapper() {
  const {auth} = useAuthContext();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate('/login');
    }
  }, [navigate, auth.authenticated]);

  const {props} = useQuery<TimesheetsPageQuery>(query);
  if (!props) {
    return <> </>;
  }
  return <TimesheetsPage node={props?.viewer.user} />;
}
